import { SavedPhoneFilter } from '@capturi/api-filters'
import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import {
  PeriodDefinition,
  PeriodDefinitions,
  SavedPhoneFilterButton,
  SegmentStatesProvider,
  SingleSegmentContainer,
  toFilterValues,
  useFilterDefinitions,
  useSavedFilter,
  useSegmentStatesContext,
} from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import { ContentPlaceholder, Emoji, PageHeading } from '@capturi/ui-components'
import { Box, Grid, HStack, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import PeriodDialog from '../components/PeriodDialog'
import SegmentTrendsContainer from '../components/SegmentTrendsContainer'
import AnalysisContext from '../contexts/AnalysisContext'
import { PeriodProvider, usePeriodContext } from '../contexts/PeriodContext'
import { RoleProvider, useRoleContext } from '../contexts/RoleContext'
import {
  SegmentTrendsProvider,
  useSegmentTrendsContext,
} from '../contexts/SegmentTrendsContext'
import TrendTypeContext from '../contexts/TrendTypeContext'
import { useLogEvent } from '../events'
import useTrendsNavigation from '../hooks/useTrendsNavigation'
import useTrendsRoutes from '../routes'

const segmentOptions = [
  PeriodDefinitions.Yesterday,
  PeriodDefinitions.Last7Days,
  PeriodDefinitions.Last30Days,
  PeriodDefinitions.CurrentWeek,
  PeriodDefinitions.CurrentMonth,
  PeriodDefinitions.LastWeek,
  PeriodDefinitions.LastMonth,
]

const SegmentTrendsPage: React.FC<{
  segmentUid?: string
}> = ({ segmentUid }) => {
  usePageTitle(t`Segment Trends`)
  const { periodDef, setPeriodDef } = usePeriodContext()
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser, {})
  const trendsRoutes = useTrendsRoutes()
  const trendsNavigate = useTrendsNavigation(trendsRoutes.segmentTrends)
  const navigate = useNavigate()

  const { segment } = useSegmentTrendsContext()
  const logEvent = useLogEvent()

  const { updateState } = useSegmentStatesContext()

  const handleSegmentChanged = (
    savedFilter: SavedPhoneFilter | undefined,
  ): void => {
    if (savedFilter) {
      const values = toFilterValues(savedFilter.values ?? {})
      updateState({
        state: {
          channel: 'phone',
          values,
          savedFilter,
        },
        index: 0,
      })

      trendsNavigate({ sourceId: savedFilter.uid })
      logEvent('filter--loaded', { segment: savedFilter?.name })
    } else {
      navigate(trendsRoutes.segmentTrends())
      logEvent('filter--cleared')
    }
  }

  const onChangePeriod = (periodDef: PeriodDefinition): void => {
    setPeriodDef(periodDef)
    logEvent('date-period--changed', periodDef)
  }
  return (
    <>
      <Grid
        templateColumns={{ lg: '1fr auto' }}
        gap="1rem"
        alignItems="center"
        mb={8}
      >
        <Box>
          <PageHeading width="fit-content">
            <Trans>Segment Trend report</Trans>
          </PageHeading>
          <Text color="textMuted">
            <Trans>Precalculated trend reports for segments</Trans>
          </Text>
        </Box>

        <HStack spacing={4} justify="flex-end" alignItems="center">
          <Text fontSize="sm">
            {segment &&
              segment.processingStatus !== 'InsufficientDataError' && (
                <Trans>
                  Conversations used in analysis: {segment.conversationsUsed}
                </Trans>
              )}
          </Text>
          {segmentUid && (
            <Box ml="auto">
              <PeriodDialog
                value={periodDef}
                options={segmentOptions}
                onChange={onChangePeriod}
              />
            </Box>
          )}
        </HStack>
      </Grid>

      {!segmentUid ? (
        <ContentPlaceholder.Container mt="15vh">
          <ContentPlaceholder.Heading>
            <Emoji
              symbol="👇"
              label="finger pointing down"
              fontSize="5xl"
              pr={2}
            />
            <Trans>Choose segment</Trans>
          </ContentPlaceholder.Heading>
          <ContentPlaceholder.Body>
            <Trans>
              Show a trend report on the basis of the chosen segment
            </Trans>
          </ContentPlaceholder.Body>
          <ContentPlaceholder.Footer>
            <SavedPhoneFilterButton
              segmentTooltipLabel={t`Select a segment`}
              segmentColor="white"
              initiallyOpened={true}
              isReadOnly={true}
              allowReselectionWhenReadOnly={true}
              placeholderText={t`Choose segment`}
              savedFilter={undefined}
              onChangeSavedFilter={handleSegmentChanged}
              buttonProps={{
                background: 'primary.500',
                borderBottomColor: 'primary.600',
                _hover: {
                  background: 'primary.600',
                },
              }}
            />
          </ContentPlaceholder.Footer>
        </ContentPlaceholder.Container>
      ) : (
        <>
          <Box my={8}>
            <SingleSegmentContainer
              filterDefinitions={filterDefinitions}
              onSavedFilterChange={handleSegmentChanged}
              showExportButton={false}
              showNotificationButton={false}
              showSaveButton={false}
              showResetButton={false}
              isReadOnly={true}
              allowReselectionWhenReadOnly={true}
            />
          </Box>

          <SegmentTrendsContainer segmentUid={segmentUid} />
        </>
      )}
    </>
  )
}

const SegmentTrendsPageWrapper: React.FC<{
  segmentUid?: string
}> = ({ segmentUid }) => {
  const [role] = useRoleContext()
  const { periodDef } = usePeriodContext()

  const analysisContext = React.useMemo(
    () => ({
      useLiveData: false,
      sourceId: segmentUid,
    }),
    [segmentUid],
  )

  return (
    <SegmentTrendsProvider
      segmentUid={segmentUid}
      role={role}
      periodName={periodDef?.name}
      swrConfig={{
        suspense: false,
        dedupingInterval: 10000,
      }}
    >
      <AnalysisContext.Provider value={analysisContext}>
        <SegmentTrendsPage segmentUid={segmentUid} />
      </AnalysisContext.Provider>
    </SegmentTrendsProvider>
  )
}

export default function SegmentTrendsPageRoute(): React.ReactElement {
  const { segmentUid } = useParams()
  const { filter } = useSavedFilter(segmentUid)
  const { employeeAsDefaultSpeakerTab } = useFeatureFlags()
  return (
    <SegmentStatesProvider
      initialState={{
        channel: 'phone',
        state: {
          values: undefined,
          savedFilter: filter || undefined,
        },
      }}
    >
      <RoleProvider defaultRole={employeeAsDefaultSpeakerTab ? 'Agent' : 'All'}>
        <PeriodProvider defaultPeriod={PeriodDefinitions.Last30Days}>
          <TrendTypeContext.Provider value="Segment">
            <SegmentTrendsPageWrapper segmentUid={segmentUid} />
          </TrendTypeContext.Provider>
        </PeriodProvider>
      </RoleProvider>
    </SegmentStatesProvider>
  )
}
