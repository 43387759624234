import { useAPI } from '@capturi/api-utils'
import { SegmentTopicsResponseModel } from 'features/aiTopics/model/api'
import { Role, SegmentWithTopics } from 'features/aiTopics/model/domain'
import { useMemo } from 'react'
import { SWRConfiguration, SWRResponse } from 'swr'

import { trendsAPI } from './api'

type UseSegmentTopics = (
  segmentUid?: string,
  role?: Role,
  period?: string,
  swrConfig?: SWRConfiguration,
) => {
  segment?: SegmentWithTopics
  isLoading: boolean
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  mutate: SWRResponse<SegmentTopicsResponseModel, any>['mutate']
}

const useSegmentTopics: UseSegmentTopics = (
  segmentUid,
  role,
  period,
  swrConfig = {},
) => {
  const { data, mutate, error } = useAPI<SegmentTopicsResponseModel>(
    () =>
      segmentUid && role && period != null
        ? trendsAPI.getSegment(segmentUid, role, period)
        : null,
    {
      suspense: true,
      revalidateOnFocus: false,
      ...swrConfig,
    },
  )
  return useMemo(() => {
    return {
      segment: data,
      isLoading: !(data || error),
      mutate,
    }
  }, [data, error, mutate])
}

export default useSegmentTopics
