import { AlertProps } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { SegmentWithTopics } from 'features/aiTopics'

type StatusAlert = {
  status: AlertProps['status']
  message: string
}

function useSegmentProcessingStatusInfo(
  segment: SegmentWithTopics | undefined,
): StatusAlert | undefined {
  if (!segment) {
    return {
      status: 'error',
      message: t`Segment not found`,
    }
  }

  if (!segment.processingStatus) return undefined

  switch (segment.processingStatus) {
    case 'Error':
      return {
        status: 'error',
        message: t`An error occurred while processing trends`,
      }
    case 'InsufficientDataError': {
      return {
        status: 'info',
        message: t`There are not enough conversations for the chosen time period.`,
      }
    }
    case 'Processing':
      return {
        status: 'info',
        message:
          segment.topics.length > 0
            ? t`Trends are currently being calculated`
            : t`There are no trends for this segment yet. The calculation of trends is currently ongoing and can take up to 20 minutes.`,
      }
    default:
      return undefined
  }
}

export default useSegmentProcessingStatusInfo
