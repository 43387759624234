import constate from 'constate'
import { Role, SegmentWithTopics } from 'features/aiTopics/model/domain'
import { SWRConfiguration } from 'swr'

import useSegmentTopics from '../data/useSegmentTopics'

function useSegmentTrends({
  segmentUid,
  role,
  periodName,
  swrConfig,
}: {
  segmentUid?: string
  role?: Role
  periodName?: string
  swrConfig?: SWRConfiguration
}): {
  segment?: SegmentWithTopics
  isLoading: boolean
} {
  const { segment, isLoading } = useSegmentTopics(
    segmentUid,
    role,
    periodName,
    swrConfig,
  )

  return {
    segment,
    isLoading,
  }
}

const [SegmentTrendsProvider, useSegmentTrendsContext] =
  constate(useSegmentTrends)

export { SegmentTrendsProvider, useSegmentTrendsContext }
