import { Alert, AlertDescription, AlertIcon, Box } from '@chakra-ui/react'
import { Topic, TopicPhrase } from 'features/aiTopics'
import { Route, Routes } from 'react-router-dom'
import {
  PlaySegmentSnippetsRouter,
  TrendMegaDrawerRouter,
} from '../drawer/routers'

import React from 'react'
import { useSegmentTrendsContext } from '../contexts/SegmentTrendsContext'
import { useLogEvent } from '../events'
import useSegmentProcessingStatusInfo from '../hooks/useSegmentProcessingStatusInfo'
import useTrendsNavigation from '../hooks/useTrendsNavigation'
import useTrendsRoutes from '../routes'
import TrendsDataTabs from './TrendsDataTabs'

const SegmentTrendsContainer: React.FC<{
  segmentUid?: string
}> = ({ segmentUid }) => {
  const trendsRoutes = useTrendsRoutes()
  const navigate = useTrendsNavigation(trendsRoutes.segmentTrends)
  const logEvent = useLogEvent()

  // Fetch segment topics
  const { segment, isLoading } = useSegmentTrendsContext()

  const navigateRef = React.useRef({ navigate })
  React.useEffect(() => {
    navigateRef.current.navigate = navigate
  }, [navigate])

  const onSelectTopic = React.useCallback(
    (
      topic: Topic,
      phrase?: TopicPhrase,
      sortId?: string,
      sortDesc?: boolean,
    ): void => {
      if (segmentUid == null) return
      logEvent('trends-on-trends--opened')
      navigateRef.current.navigate({
        sourceId: segmentUid,
        topicIndex: topic.index,
        phraseIndex: phrase?.index,
        trends: true,
        sortId,
        sortDesc,
      })
    },
    [logEvent, segmentUid],
  )

  const onPlaySnippets = React.useCallback(
    (
      topic: Topic,
      phrase?: TopicPhrase,
      sortId?: string,
      sortDesc?: boolean,
    ): void => {
      if (segmentUid == null) return
      logEvent('view-topic-phrase-conversations')
      navigate({
        sourceId: segmentUid,
        topicIndex: topic.index,
        conversations: true,
        phraseIndex: phrase?.index,
        sortId,
        sortDesc,
      })
    },
    [logEvent, navigate, segmentUid],
  )

  const alertInfo = useSegmentProcessingStatusInfo(segment)

  return (
    <Box>
      <TrendsDataTabs
        isLoading={isLoading}
        topics={segment?.topics ?? []}
        onSelectTopic={onSelectTopic}
        onPlaySnippets={onPlaySnippets}
      >
        {!isLoading && alertInfo && (
          <Alert status={alertInfo.status}>
            <AlertIcon />
            <AlertDescription>{alertInfo.message}</AlertDescription>
          </Alert>
        )}
      </TrendsDataTabs>
      <Routes>
        <Route
          path="/topics/:topicIndex/conversations"
          element={<PlaySegmentSnippetsRouter onNavigate={navigate} />}
        />
        <Route
          path="/topics/:topicIndex/trends/*"
          element={<TrendMegaDrawerRouter />}
        />
      </Routes>
    </Box>
  )
}

export default SegmentTrendsContainer
