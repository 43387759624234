import { SavedPhoneFilter, filtersAPI } from '@capturi/api-filters'
import { useAPI } from '@capturi/api-utils'
import { useMemo } from 'react'
import { SWRConfiguration, SWRResponse } from 'swr'

function useSavedFilter(
  uid?: string,
  swrConfig: SWRConfiguration = {},
): {
  filter: SavedPhoneFilter | undefined | null
  isLoading: boolean
  mutate: SWRResponse<SavedPhoneFilter, unknown>['mutate']
} {
  const { data, error, mutate } = useAPI<SavedPhoneFilter>(
    () => (uid ? filtersAPI.getFilter(uid) : null),
    {
      suspense: true,
      revalidateOnFocus: false,
      ...swrConfig,
    },
  )

  return useMemo(() => {
    return {
      filter: data,
      isLoading: !(error || data),
      mutate,
    }
  }, [data, error, mutate])
}

export default useSavedFilter
