import { PeriodDefinition, formatNamedPeriod } from '@capturi/filters'
import { List, ListItem } from '@capturi/ui-components'
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import React from 'react'

import PeriodDefButton from './PeriodDefButton'

const PeriodDialog: React.FC<{
  value: PeriodDefinition
  options: PeriodDefinition[]
  onChange?: (periodDef: PeriodDefinition) => void
}> = ({ value, options, onChange }) => {
  return (
    <Popover placement="auto-end" variant="responsive">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <PeriodDefButton />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody p={0}>
              <List fontWeight="medium">
                {options.map((o) => (
                  <ListItem
                    button
                    color={value.name === o.name ? 'brand.primary' : 'black'}
                    key={o.name}
                    onClick={() => {
                      onChange?.(o)
                      onClose()
                    }}
                  >
                    {formatNamedPeriod(o)}
                  </ListItem>
                ))}
              </List>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

export default PeriodDialog
